import React from "react";
import Grid from "@material-ui/core/Grid";

class Footer extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={3} style={{ marginTop: 0 }} className="footer">
          <Grid item xs={12} sm={4} md={2}>
            <a
              href="http://networkscienceinstitute.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/NU_NetworkScienceInstitute-white.png"
                alt="neu-logo"
                className="logo"
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <a
              href="http://mobs-lab.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/mobs-lab-logo-full_white.png"
                alt="mobs-lab-logo"
                className="logo"
                width="200"
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <a
              href="https://www.isi.it/en/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/isi-logo-transparent-white.png"
                width="200"
                alt="isi-logo"
                className="logo"
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={4} md={2} style={{ textAlign: "center" }}>
            <a
              href="https://www.cuebiq.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/Cuebiq-Logo@3x.png"
                width="160"
                alt="cuebiq-logo"
                style={{ display: "inline-block" }}
                className="logo"
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={4} md={2} style={{ textAlign: "center" }}>
            <a
              href="https://hms.harvard.edu//"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/hmslogo.png"
                width="160"
                alt="hms-logo"
                style={{ display: "inline-block" }}
                className="logo"
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={4} md={2} style={{ textAlign: "center" }}>
            <a
              href="http://www.ox.ac.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/university-of-oxford.png"
                width="160"
                alt="fbk-logo"
                style={{ display: "inline-block" }}
                className="logo"
              />
            </a>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 0, padding: "20px 0", textAlign: "center" }}
          className="footer"
        >
          <Grid item xs={12}>
            <hr color="#4C5B5F" />
            <a
              href="http://gleamproject.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/gleam-project-logo-compact-05.png"
                width="140"
                alt="gleam-logo"
                style={{ marginTop: 20 }}
              />
            </a>
            <p style={{ color: "#cccccc", fontSize: 12 }}>© 2020</p>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Footer;
