import React from "react";
import Grid from "@material-ui/core/Grid";

class Footer extends React.Component {
  render() {
    return (
      <div>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 0 }}
          className="footer"
        >
          <Grid item xs sm></Grid>
          <Grid item xs={12} sm={4} md={2}>
            <a
              href="http://networkscienceinstitute.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/NU_NetworkScienceInstitute-white.png"
                alt="neu-logo"
                className="logo"
              />
            </a>
            <a
              href="http://mobs-lab.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/mobs-lab-logo-full_white.png"
                alt="mobs-lab-logo"
                className="logo"
                width="200"
              />
            </a>
          </Grid>
         
          <Grid item xs={12} sm={4} md={2}>
            <a
              href="https://www.fredhutch.org/en.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/red-hutch-white.png"
                width="200"
                alt="fred-hutch-logo"
                className="logo"
              />
            </a>
            <a
              href="http://www.ufl.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/UF-white.png"
                width="200"
                alt="uf-logo"
                className="logo"
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <a
              href="https://www.isi.it/en/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/isi-logo-transparent-white.png"
                width="200"
                alt="isi-logo"
                className="logo"
              />
            </a>
            <a
              href="https://www.fic.nih.gov/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/FOGARTY.png"
                width="40"
                alt="fogarty-logo"
                className="logo"
                style={{ margin: "40px auto" }}
              />
            </a>
            
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <a
              href="https://www.gre.ac.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/UoG_white.png"
                alt="uog-logo"
                width="200"
                className="logo"
              />
            </a>
            <a
              href="https://www.iu.eu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/IU-white.png"
                width="40"
                alt="iu-logo"
                style={{ margin: "20px auto" }}
                className="logo"
              />
            </a>
          </Grid>
          <Grid item xs sm></Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 0, padding: "20px 0", textAlign: "center" }}
          className="footer"
        >
          <Grid item xs={12}>
            <hr color="#4C5B5F"/>
            <a
              href="http://gleamproject.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/gleam-project-logo-compact-05.png"
                width="140"
                alt="gleam-logo"
                style={{ marginTop: 20 }}
              />
            </a>
            <p style={{ color: "#cccccc", fontSize: 12 }}>© 2020</p>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Footer;
