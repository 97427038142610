import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { italyDevUrl } from "../constants/urls";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import Paper from "@material-ui/core/Paper";
import axios from "axios";
import getDates from "../constants/dates";

function createData(
  name,
  noLockdown,
  lockdownIndefinite,
  lift1,
  lift2,
  fullLift
) {
  return {
    name,
    noLockdownMedian: noLockdown.median,
    noLockdownBounds: noLockdown.bounds,
    lockdownIndefiniteMedian: lockdownIndefinite.median,
    lockdownIndefiniteBounds: lockdownIndefinite.bounds,
    lift1Median: lift1.median,
    lift1Bounds: lift1.bounds,
    lift2Median: lift2.median,
    lift2Bounds: lift2.bounds,
    fullLiftMedian: fullLift.median,
    fullLiftBounds: fullLift.bounds,
  };
}

const styles = {
  TableHead: {
    background: "#c8f6e9",
    fontWeight: 600,
    width: "100%",
  },
};

class SummaryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        "No Lockdown": {
          "Total Deaths": { median: 0, lower: 0, upper: 0 },
          "Total Infectious": { median: 0, lower: 0, upper: 0 },
          "Attack Rate": { median: 0, lower: 0, upper: 0 },
        },
        "Lockdown kept indefinitely": {
          "Total Deaths": { median: 0, lower: 0, upper: 0 },
          "Total Infectious": { median: 0, lower: 0, upper: 0 },
          "Attack Rate": { median: 0, lower: 0, upper: 0 },
        },
        "Lift 1 (May 4th): 50% work sector; 10% community; schools closed; 50% mobility": {
          "Total Deaths": { median: 0, lower: 0, upper: 0 },
          "Total Infectious": { median: 0, lower: 0, upper: 0 },
          "Attack Rate": { median: 0, lower: 0, upper: 0 },
        },
        "Lift 2 (May 4th): 70% work sector; 50% community; schools closed; 50% mobility": {
          "Total Deaths": { median: 0, lower: 0, upper: 0 },
          "Total Infectious": { median: 0, lower: 0, upper: 0 },
          "Attack Rate": { median: 0, lower: 0, upper: 0 },
        },
        "Full Lift (May 4th): social distancing measures are lifted. Full mobility resumed. Schools and education activities are resumed": {
          "Total Deaths": { median: 0, lower: 0, upper: 0 },
          "Total Infectious": { median: 0, lower: 0, upper: 0 },
          "Attack Rate": { median: 0, lower: 0, upper: 0 },
        },
      },
    };
  }

  async componentDidMount() {
    const { date } = this.props;
    const isoDate = new Date(date).toISOString().split("T")[0];

    const response = await axios.get(
      `${italyDevUrl}aggregateData?state=Italy&date=${isoDate}`
    );
    this.setState({
      data: response.data,
    });
  }

  async componentDidUpdate(prevProps) {
    const { state, date } = this.props;
    const isoDate = new Date(this.props.date).toISOString().split("T")[0];

    if (prevProps.state !== state || prevProps.date !== date) {
      const response = await axios.get(
        `${italyDevUrl}aggregateData?state=Italy&date=${isoDate}`
      );
      this.setState({
        data: response.data,
      });
    }
  }

  getString = (key, metric, data) => {
    let median = "";
    let bounds = "";
    if (metric === "Attack Rate") {
      median = `${parseFloat(data[key][metric].median * 100).toFixed(1) || 0}%`;
      bounds = `[${
        parseFloat(data[key][metric].lower.toFixed(3) * 100).toFixed(1) || 0
      }% - ${parseFloat(data[key][metric].upper * 100).toFixed(1) || 0}%]`;
    } else {
      median = `${this.numberWithCommas(
        Math.round(data[key][metric].median || 0)
      )}`;
      bounds = `[${this.numberWithCommas(
        Math.round(data[key][metric].lower || 0)
      )} - ${this.numberWithCommas(Math.round(data[key][metric].upper || 0))}]`;
    }

    return { median, bounds };
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  render() {
    const { data } = this.state;
    const { lastDate } = this.props;
    const rows = [
      createData(
        "Total Deaths",
        this.getString("No Lockdown", "Total Deaths", data),
        this.getString("Lockdown kept indefinitely", "Total Deaths", data),
        this.getString(
          "Lift 1 (May 4th): 50% work sector; 10% community; schools closed; 50% mobility",
          "Total Deaths",
          data
        ),
        this.getString(
          "Lift 2 (May 4th): 70% work sector; 50% community; schools closed; 50% mobility",
          "Total Deaths",
          data
        ),
        this.getString(
          "Full Lift (May 4th): social distancing measures are lifted. Full mobility resumed. Schools and education activities are resumed",
          "Total Deaths",
          data
        )
      ),
      createData(
        "Cumulative Infection Attack Rate",
        this.getString("No Lockdown", "Attack Rate", data),
        this.getString("Lockdown kept indefinitely", "Attack Rate", data),
        this.getString(
          "Lift 1 (May 4th): 50% work sector; 10% community; schools closed; 50% mobility",
          "Attack Rate",
          data
        ),
        this.getString(
          "Lift 2 (May 4th): 70% work sector; 50% community; schools closed; 50% mobility",
          "Attack Rate",
          data
        ),
        this.getString(
          "Full Lift (May 4th): social distancing measures are lifted. Full mobility resumed. Schools and education activities are resumed",
          "Attack Rate",
          data
        )
      ),
    ];

    return (
      <Container maxWidth={false} disableGutters>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 0 }}
          className="summary"
        >
          <Grid item xs></Grid>
          <Grid item xs={12} sm={9}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                className="form-control"
                style={{ paddingBottom: "15px" }}
              >
                <InputLabel htmlFor="filled-age-native-simple">
                  PROJECTIONS FOR:
                </InputLabel>
                <Select
                  native
                  inputProps={{
                    name: "age",
                    id: "filled-age-native-simple",
                  }}
                  onChange={(e) => this.props.handleDateChange(e.target.value)}
                >
                  {getDates(lastDate).map((date) => (
                    <option value={date} key={date}>
                      {date}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs></Grid>
            <TableContainer component={Paper}>
              <Table aria-label="summary table">
                <TableHead style={styles.TableHead}>
                  <TableRow>
                    <TableCell style={{ width: 250 }}>Italy</TableCell>
                    <TableCell style={{ width: 250 }} align="center">
                      Lockdown kept indefinitely
                    </TableCell>
                    <TableCell style={{ width: 250 }} align="center">
                      Lift 1
                    </TableCell>
                    <TableCell style={{ width: 250 }} align="center">
                      Lift 2
                    </TableCell>
                    <TableCell style={{ width: 500 }} align="center">
                      Full Lift
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.lockdownIndefiniteMedian}
                        <br />
                        {row.lockdownIndefiniteBounds}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.lift1Median}
                        <br />
                        {row.lift1Bounds}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.lift2Median}
                        <br />
                        {row.lift2Bounds}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.fullLiftMedian}
                        <br />
                        {row.fullLiftBounds}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      </Container>
    );
  }
}

export default SummaryTable;
