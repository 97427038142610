import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

class About extends React.Component {
  render() {
    return (
      <Container maxWidth={false} disableGutters style={{ marginTop: 12 }}>
        <Grid container spacing={0} className="about" id="about">
          <Grid item xs sm></Grid>
          <Grid item xs={12} sm={8}>
            <h2>About</h2>
            <p>
              To study the spatiotemporal COVID-19 spread, we use the Global
              Epidemic and Mobility Model (GLEAM), an individual-based,
              stochastic, and spatial epidemic model [
              <a
                href="https://www.pnas.org/content/106/51/21484"
                target="_blank"
                rel="noopener noreferrer"
              >
                1
              </a>
              ,{" "}
              <a
                href="https://www.sciencedirect.com/science/article/abs/pii/S1877750310000438?via%3Dihub"
                target="_blank"
                rel="noopener noreferrer"
              >
                2
              </a>
              ,{" "}
              <a
                href="https://bmcmedicine.biomedcentral.com/articles/10.1186/1741-7015-10-165"
                target="_blank"
                rel="noopener noreferrer"
              >
                3
              </a>
              ,{" "}
              <a
                href="https://www.pnas.org/content/114/22/E4334"
                target="_blank"
                rel="noopener noreferrer"
              >
                4
              </a>
              ]. GLEAM uses real-world data to perform in-silico simulations of
              the spatial spread of infectious diseases at the global level
              <strong>.</strong> &nbsp;We use the model to analyze the
              spatiotemporal spread and magnitude of the COVID-19 epidemic in
              Italy. The model generates an ensemble of possible epidemic
              projections described by the number of newly generated infections,
              times of disease arrival in different regions, and the number of
              traveling infection carriers. Approximate Bayesian Computation is
              used to estimate the posterior distribution of the basic
              parameters of the model. The calibration of the global model for
              COVID-19 is reported in{" "}
              <a
                href="https://science.sciencemag.org/content/early/2020/03/05/science.aba9757"
                target="_blank"
                rel="noopener noreferrer"
              >
                Science
              </a>
              . The Italy model considers the timeline of mitigation
              interventions that are integrated as detailed in the following{" "}
              <a
                href="https://uploads-ssl.webflow.com/58e6558acc00ee8e4536c1f5/5eb9fc443c9a2cb1061df60a_GLEAM_web_ITA.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                model description
              </a>
              . The projections will be regularly updated as new data and
              information about mitigation policies become available.
              Sensitivity analysis on the basic parameters is routinely
              performed along with the baseline projections considered. In order
              to calculate the number of deaths the model uses estimates of
              COVID-19 severity from available data [
              <a
                href="https://www.thelancet.com/journals/laninf/article/PIIS1473-3099(20)30243-7/fulltext"
                target="_blank"
                rel="noopener noreferrer"
              >
                5
              </a>
              ,{" "}
              <a
                href="https://www.nature.com/articles/s41591-020-0822-7"
                target="_blank"
                rel="noopener noreferrer"
              >
                6
              </a>
              ].
            </p>
            <p>
              SOCIAL DISTANCING SCENARIOS <br />
              We analyze here the following scenarios concerning the lifting of
              social distancing measures. <br />
              <br /> <strong>Lockdown kept indefinitely</strong>: This scenario
              has been calibrated on the past epidemiological data and considers
              only essential work activities are allowed. We also assume a 20%
              transmissibility reduction due to the awareness of population and
              behavioral changes. <br />
              <strong>Lift 1</strong>: Starting on May 4th, 2020 we assume that
              schools are closed, interaction in workplaces is reduced by 50%,
              and a 90% community social interaction reduction. Mobility is
              reduced of 50%. We also assume a 20% transmissibility reduction
              due to the awareness of population and behavioral changes. Total
              transmissibility reduction amounts to 60%. <br />{" "}
              <strong>Lift 2</strong>: Starting on May 4th, 2020 e assume that
              schools are closed, interaction in workplaces is reduced by 30%,
              and a 50% community social interaction reduction. Mobility is
              reduced of 50%. We also assume a 20% transmissibility reduction
              due to the awareness of population and behavioral changes. Total
              transmissibility reduction amounts to 50%. <br />
              <strong>Full Lift</strong>: Starting on May 4th, 2020 social
              distancing measures are lifted. Full mobility resumed. Schools are
              open according to schedule. <br /> <br />
              None of the above scenarios should be considered as exactly
              mimicking the Phase 2 government implementation, but provide an
              assessment of the effect of different approaches to lift social
              distancing policies. The current implementation of the model
              focuses on the impact of social distancing policies; thus, it does
              not address the role of other strategies such as contact tracing
              in reducing transmission. These should still be considered as part
              of any epidemic response. The model does not consider seasonal
              drivers on the transmissibility of SARS-CoV-2, such as temperature
              or humidity.
            </p>
            <p className="small">
              <strong>Disclaimer: </strong>There are large uncertainties around
              the transmission of COVID-19, the effectiveness of different
              policies and the extent to which the population is compliant to
              social distancing measures. The presented material is based on
              modeling scenario assumptions informed by current knowledge of the
              disease and subject to change as more data become available.
              Future decisions on when and for how long to relax mitigation
              policies will be informed by ongoing surveillance. Additional
              modeling and data studies are required to assess the level and
              effectiveness of additional non-pharmaceuticals interventions
              required to lift current social distancing measures.
            </p>
          </Grid>
          <Grid item xs sm></Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          style={{ marginTop: 0, paddingBottom: 0 }}
          className="team"
          id="team"
        >
          <Grid item xs sm></Grid>
          <Grid item xs={12} sm={8}>
            <h2>Team</h2>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4}>
                <h5>Northeastern University</h5>
                <ul>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/alessandro-vespignani"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ​Alessandro Vespignani
                      <br />
                    </a>
                    <span className="small">
                      (to whom correspondence should be addressed)
                    </span>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/alessandro-vespignani"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ‍
                    </a>
                    <a
                      href="https://www.networkscienceinstitute.org/people/matteo-chinazzi"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Matteo Chinazzi
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/jessica-davis"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Jessica T. Davis
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/kunpeng-mu"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ​Kunpeng Mu
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/ana-pastore-y-piontti"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ana Pastore y Piontti
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/nicole-samay"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Nicole Samay
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/xinyue-xiong"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Xinyue Xiong
                    </a>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={4}>
                <h5>Fred Hutchinson Cancer Research Center</h5>
                <ul>
                  <li>
                    <a
                      href="https://www.fredhutch.org/en/faculty-lab-directory/halloran-elizabeth.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      M. Elizabeth Halloran
                    </a>
                  </li>
                </ul>
                <h5>University of Florida</h5>
                <ul>
                  <li>
                    <a
                      href="http://biostat.ufl.edu/about/people/faculty/longini-ira/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ira M. Longini, Jr.
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://biostat.ufl.edu/about/people/faculty/dean-natalie-e/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Natalie E. Dean
                    </a>
                  </li>
                </ul>
                <h5>NIH Fogarty Center</h5>
                <ul>
                  <li>Cécile Viboud</li>
                  <li>Kaiyuan Sun</li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={4}>
                <h5>ISI Foundation</h5>
                <ul>
                  <li>
                    <a
                      href="https://www.isi.it/en/people/maria-litvinova"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Maria Litvinova
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.isi.it/en/people/corrado-gioannini"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Corrado Gioannini
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.isi.it/en/people/luca-rossi"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Luca Rossi
                    </a>
                  </li>
                </ul>
                <h5>Bruno Kessler Foundation</h5>
                <ul>
                  <li>
                    <a
                      href="https://ict.fbk.eu/people/detail/marco-ajelli/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="person-link"
                    >
                      Marco Ajelli
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ict.fbk.eu/people/detail/stefano-merler/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="person-link"
                    >
                      Stefano Merler
                    </a>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs sm></Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          style={{ marginTop: 0, paddingTop: 0 }}
          className="team"
          id="team"
        >
          <Grid item xs sm></Grid>
          <Grid item xs={12} sm={8}>
            <h2>Acknowledgments</h2>
            <p class="small">
              We thank{" "}
              <a
                href="https://www.linkedin.com/in/agastyamondal/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Agastya Mondal
              </a>{" "}
              and{" "}
              <a
                href="https://www.linkedin.com/in/robelkassa"
                rel="noopener noreferrer"
                target="_blank"
              >
                Robel Kassa
              </a>{" "}
              for the development of this dashboard.
            </p>
          </Grid>
          <Grid item xs sm></Grid>
        </Grid>
      </Container>
    );
  }
}

export default About;
