import React from "react";
import {
  AppBar,
  Button,
  ButtonGroup,
  Toolbar,
  Typography,
} from "@material-ui/core";

const styles = {
  toolbar: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  appBarTitle: {
    flexGrow: 1,
    color: "#ecfdf6",
    fontWeight: 400,
    fontSize: 30,
  },
  title: {
    color: "#ecfdf6",
    fontSize: 30,
    flexGrow: 1,
  },
  boldTitle: {
    fontWeight: 600,
    color: "#ecfdf6",
  },
  buttonLink: {
    color: "#ecfdf6",
    textTransform: "none",
    border: 0,
  },
};
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
    };
  }
  render() {
    return (
      <AppBar
        position="static"
        style={{ backgroundColor: "#4c5b5f", boxShadow: "none" }}
      >
        <Toolbar style={styles.toolbar}>
          <Typography variant="h2" style={styles.title}>
            <img src="/covid-19-logo-us.png" height="75" alt="logo" />
          </Typography>
          <div>
            <ButtonGroup
              orientation="horizontal"
              variant="text"
              aria-label="vertical text button group"
              id="menuGroup-primo"
            >
              <Button href="#about" style={styles.buttonLink}>
                About
              </Button>
              <Button href="#team" style={styles.buttonLink}>
                Team
              </Button>
              <Button href="/mobility" style={styles.buttonLink} id="primo">
                Mobility: US
              </Button>
              <Button href="/italy" style={styles.buttonLink} id="primo">
                Modeling: Italy
              </Button>
            </ButtonGroup>
            <ButtonGroup
              orientation="horizontal"
              variant="text"
              aria-label="vertical text button group"
              id="menuGroup"
            >
              <Button href="#infdthproj" style={styles.buttonLink}>
                Weekly Deaths
              </Button>
              <Button href="#icubedproj" style={styles.buttonLink}>
                Hospital Beds
              </Button>
            </ButtonGroup>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default Header;
