import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { Checkbox, Container, TextField } from "@material-ui/core";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class CheckboxesTags extends React.Component {
  render() {
    const {
      availableLocations,
      handleStateSelect,
      handleCSASelect,
    } = this.props;

    const topLevelLocations = availableLocations
      .filter((loc) => loc.hierarchy === 0 || loc.hierarchy === 1)
      .map((loc) => loc.name);
    const csaLocations = availableLocations
      .filter((loc) => loc.hierarchy === 2)
      .map((loc) => loc.name);

    return (
      <Container>
        <h4>Select Location(s)</h4>
        <Autocomplete
          multiple
          fullWidth
          style={{ marginBottom: 10 }}
          onChange={(e, values) => {
            handleStateSelect(values);
          }}
          options={topLevelLocations}
          disableCloseOnSelect
          getOptionDisabled={(option) => option === "Vermont"}
          getOptionLabel={(option) => option}
          defaultValue={["United States"]}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option === "Vermont" ? "Vermont *" : option}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="State(s)" />
          )}
        />
        <Autocomplete
          style={{ marginTop: 10 }}
          onChange={(e, values) => {
            handleCSASelect(values);
          }}
          multiple
          fullWidth
          options={csaLocations}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Combined Statistical Area(s)" />
          )}
        />
        {/* </Sticky> */}
      </Container>
    );
  }
}

export default CheckboxesTags;
