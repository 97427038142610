const COLORS = [
    "#7CE4BB",
    "#FCE11A",
    "#FFA828",
    "#69CFEF",
    "#6E9CD9",
    "#FF714D",
    "#DA5588",
    "#DFD5C3",
    "#8A8279",
    "#DEEB5A",
    "#7ED66D",
    "#AAF2F1",
    "#118687",
    "#2EAEA5",
    "#FF4462",
    "#C3464F",
    "#C997D8",
    "#4F71AE",
  ];
export { COLORS };
