import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

class About extends React.Component {
  render() {
    return (
      <Container maxWidth={false} disableGutters style={{ marginTop: 0 }}>
        {/* <Grid container spacing={0} className="about" id="about">
          <Grid item xs sm></Grid>
          <Grid item xs={12} sm={8}>
            <h2>About</h2>
            <p>
              As of April 7, 2020, 95% of people in the U.S. are being told by
              their states' governors to stay home [
              <a
                href="https://www.nytimes.com/interactive/2020/us/coronavirus-stay-at-home-order.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>1</strong>
              </a>
              ,{" "}
              <a
                href="https://www.whitehouse.gov/briefings-statements/coronavirus-guidelines-america/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>2</strong>
              </a>
              ] due to the COVID-19 pandemic. These non-pharmaceutical
              interventions (NPIs) are aimed at minimizing the number of
              contacts that a given person might have in a day, which will in
              turn minimize the number of new cases of COVID-19. Here, we
              provide estimates for the extent to which people are complying
              with these physical distancing guidelines. We do this through
              three proxy measures: individual <strong>mobility</strong>, volume
              of <strong>commutes</strong> to/from work, and daily total{" "}
              <strong>contact</strong> events.
            </p>
            <p>
              <ul>
                <li>
                  <strong>Commutes:</strong> To calculate commuting behavior, we
                  filter users to include only those who have at least two
                  “personal areas”, home and work, as inferred from the data.
                  Moving between these two personal areas within a day is a
                  commuting event. From this, we construct a network with census
                  tracts as nodes and weighted edges between tracts representing
                  the number of people moving between the tracts in a given day.
                </li>
                <li>
                  <strong>Mobility:</strong> To estimate individual mobility, we
                  calculate the radius of gyration [
                  <a
                    href="https://www.nature.com/articles/nature06958"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>3</strong>
                  </a>
                  ] of anonymous users throughout a day. The radius of gyration
                  measures the mean square deviation of the distances traveled
                  by a user on a given day, as measured from the center of mass
                  of the trajectory. Larger radii of gyration correspond to
                  trajectories with positions that are far away from the average
                  position.
                </li>
                <li>
                  <strong>Contacts:</strong> To estimate daily contact events we
                  first split each day into 5-minute time bins, and we assign
                  spatial (lat./lon.) coordinates to an 8-character string,
                  known as a geohash. For every location ping, we assign the
                  coordinates to its corresponding geohash and the timestamp to
                  its corresponding time bin. We finally define two users to be
                  co-located if they are observed in the same geohash in the
                  same time bin. We calculate the total contact events per day
                  as well as the total unique contacts per day. This analysis
                  explicitly excludes users’ personal areas (i.e. home and work
                  locations).
                </li>
              </ul>
            </p>
          </Grid>
          <Grid item xs sm></Grid>
        </Grid> */}
        <Grid container spacing={0} id="data" className="about" style={{ padding: "80px 0" }}>
          <Grid item xs sm></Grid>
          <Grid item xs={12} sm={8}>
            <h2>Data</h2>
            <p>
              The data used in this dashboard are provided by Cuebiq, a location
              intelligence and measurement platform. Through its Data for Good
              program [
              <a
                href="https://www.cuebiq.com/about/data-for-good/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>1</strong>
              </a>
              ], Cuebiq provides access to aggregated and privacy-enhanced
              mobility data for academic research and humanitarian initiatives.
              These first-party data are collected from anonymized users who
              have opted in to provide access to their GPS location data
              anonymously, through a GDPR-compliant framework. In addition to
              anonymizing all data, the data employs privacy-preserving
              techniques (referred to as “upleveling”) to reduce risk of
              re-identification, such as aggregating the inferred home and work
              areas to the census block group level [
              <a
                href="https://www.census.gov/programs-surveys/geography/about/glossary.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>2</strong>
              </a>
              ]. This allows for demographic analysis while obfuscating the true
              home location of anonymous users and preventing misuse of data.
            </p>
            <p>
              This dashboard includes data aggregated at various levels. We
              report trends at the national level, state level, as well as the
              50 most populous Combined Statistical Areas (CSAs), which can be
              thought of as large metropolitan areas (e.g. the New York City CSA
              extends into New Jersey, Connecticut and Pennsylvania)
            </p>
            <p className="small">
              <strong>Disclaimer: </strong>The results are estimates. The
              dataset used here contains a sample of people in the United
              States, and as such there is bound to be variability in the
              coverage and representativeness of the results above. The
              presented material is subject to change as more data become
              available. Future decisions on when and for how long to relax
              mitigation policies will be informed by ongoing surveillance.
              Additional reports are required to assess the level and
              effectiveness of additional non-pharmaceuticals interventions
              required to lift current social distancing measures.
            </p>
            <p className="small">
              * Results for Vermont are currently not reported due to lack of sufficient data coverage.
            </p>
          </Grid>
          <Grid item xs sm></Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          style={{ marginTop: 0 }}
          className="team"
          id="team"
        >
          <Grid item xs sm={2}></Grid>
          <Grid item xs={12} sm={5}>
            <h2>Team</h2>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={6}>
                <h5>Northeastern University</h5>
                <ul>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/alessandro-vespignani"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Alessandro Vespignani
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/matteo-chinazzi"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Matteo Chinazzi
                    </a>
                    <span className="small">
                      (to whom correspondence should be addressed)
                    </span>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/brennan-klein"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Brennan Klein
                      <br />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/timothy-larock"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Timothy LaRock
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/stefan-mccabe"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Stefan McCabe
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/leonardo-torres"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ​Leo Torres
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/david-lazer"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      David Lazer
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/tina-eliassi-rad"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tina Eliassi-Rad
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/samuel-v-scarpino"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Samuel V. Scarpino
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.networkscienceinstitute.org/people/lisa-friedland"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Lisa Friedland
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.khoury.northeastern.edu/people/maciej-kos/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Maciej Kos
                    </a>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h5>Cuebiq, Inc.</h5>
                <ul>
                  <li>Filippo Privitera</li>
                  <li>Brennan Lake</li>
                </ul>
                <h5>Oxford University</h5>
                <ul>
                  <li>Moritz U.G. Kraemer</li>
                </ul>
                <h5>Boston Children's Hospital</h5>
                <ul>
                  <li>John S. Brownstein</li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <h2>Acknowledgments</h2>
            <p class="small">
              We thank Ciro Cattuto, Michele Tizzoni, and Zachary Cohen for
              their help understanding the details of Cuebiq data and Esteban
              Moro for his comments. We also thank Chia-Hung Yang for coding
              assistance. MC and AV acknowledge support from Google Cloud
              Healthcare and Life Sciences Solutions via GCP research credits
              program. The findings and conclusions in this study are those of
              the authors and do not necessarily represent the official position
              of the funding agencies, the National Institutes of Health or U.S.
              Department of Health and Human Services. BK acknowledges support
              from the National Defense Science & Engineering Graduate
              Fellowship (NDSEG). TER, LT, and TL were supported in part by NSF
              IIS-1741197, Combat Capabilities Development Command Army Research
              Laboratory under Cooperative Agreement Number W911NF-13-2-0045,
              and Under Secretary of Defense for Research and Engineering under
              Air Force Contract No. FA8702-15- D-0001.
            </p>
            <p class="small">
              <strong>Web Development & Design</strong>
              <br />
              We thank{" "}
              <a
                href="https://www.linkedin.com/in/agastyamondal/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Agastya Mondal
              </a>
              ,{" "}
              <a
                href="https://www.linkedin.com/in/robelkassa"
                rel="noopener noreferrer"
                target="_blank"
              >
                Robel Kassa
              </a>
              , and{" "}
              <a
                href="https://www.networkscienceinstitute.org/people/nicole-samay"
                rel="noopener noreferrer"
                target="_blank"
              >
                Nicole Samay
              </a>{" "}
              for the development of this dashboard.
            </p>
          </Grid>
          <Grid item xs sm={2}></Grid>
        </Grid>
      </Container>
    );
  }
}

export default About;
